import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { get } from '../lib/nodash';

import { useStoryblokState } from '../lib/storyblok';
import Seo from '../components/Seo';
import PageCollection from '../components/Collection/PageCollection';
import usePageBodyComponents from '../components/DynamicPage/usePageBodyComponents';
import { PRODUCT_LIST_VIEWED } from '../lib/analytics/segmentActions';
import { track } from '../lib/analytics';
import deNodify from '../lib/deNodify';

const CollectionTemplate = ({
  data: { collection, paintCollections, page, site },
  pageContext: { finish, paintArea, canonical },
  location,
}) => {
  const canonicalUrl =
    site.siteMetadata.siteUrl + (canonical || location.pathname);
  const story = useStoryblokState(page || {});
  const components = usePageBodyComponents(story || {});
  const collectionType = collection.type?.value;

  const interpolatedTitle = paintArea
    ? collection.title.indexOf('Paint') > -1
      ? `${collection.title.substr(
          0,
          collection.title.indexOf('Paint')
        )} ${paintArea} ${collection.title.substr(
          collection.title.indexOf('Paint'),
          collection.title.length - 1
        )}`
      : collection.title
    : collection.title;

  const metaDescription = `Backdrop's curated selection of ${interpolatedTitle}. Free shipping on paint and supplies.`;
  const seoTitle =
    get('content.metafields.title', story) ||
    get('seo.title', collection) ||
    interpolatedTitle ||
    get('title', collection);
  const seoDescription =
    get('content.metafields.description', story) ||
    get('seo.description', collection) ||
    metaDescription ||
    get('description', collection);

  useEffect(() => {
    track(PRODUCT_LIST_VIEWED, {
      list_id: collection.shopifyId,
      category: collection.title,
      products: collection.products.map((x, i) => ({
        product_id: x.shopifyId,
        sku: x.variants ? (x.variants[0] || {}).sku : undefined,
        name: x.title,
        price: parseFloat(x.priceRangeV2.minVariantPrice.amount),
        position: i + 1,
        image_url: x.featuredImage?.originalSrc,
      })),
    });
  }, []);

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        canonical={canonicalUrl}
        image={collection?.image?.originalSrc}
        titleTemplate={
          collectionType === 'Wallcoverings'
            ? '%s | Backdrop x Schumacher'
            : undefined
        }
      />
      <PageCollection
        collection={collection}
        location={location}
        content={components}
        paintCollections={deNodify(paintCollections)}
        finish={finish}
        paintArea={paintArea}
        title={interpolatedTitle}
        baseUrl={location.pathname.replace(`${collection.handle}/`, '')}
      />
    </>
  );
};

CollectionTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  collection: PropTypes.string,
  collectionId: PropTypes.string,
  paintCollections: PropTypes.array,
  pages: PropTypes.array,
  slug: PropTypes.string,
  id: PropTypes.string,
};

export const query = graphql`
  query StandaloneCollection($id: String!, $slug: String!) {
    page: storyblokEntry(full_slug: { eq: $slug }) {
      full_slug
      name
      path
      slug
      content
      uuid
      internalId
    }
    collection: shopifyCollection(id: { eq: $id }) {
      id
      handle
      title
      description
      descriptionHtml
      ruleSet {
        rules {
          column
          condition
          relation
        }
      }
      image {
        originalSrc
      }
      type: metafield(key: "type", namespace: "collection") {
        value
      }
      template: metafield(key: "template", namespace: "collection") {
        value
      }
      products {
        shopifyId
        description
        handle
        id
        productType
        title
        featuredImage {
          originalSrc
        }
        tags
        metafields {
          value
          key
          namespace
        }
        designer: metafield(key: "name", namespace: "designer") {
          value
        }
        media {
          ... on ShopifyMediaImage {
            image {
              originalSrc
            }
          }
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        seo {
          description
          title
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        variants {
          shopifyId
          title
          price
        }
      }
      productsCount
      description
      shopifyId
      seo {
        description
        title
      }
    }
    paintCollections: allShopifyCollection(
      filter: {
        metafields: {
          elemMatch: {
            key: { eq: "type" }
            namespace: { eq: "collection" }
            value: { eq: "Paint" }
          }
        }
      }
      sort: {
        order: [ASC, ASC]
        fields: [
          products___variants___product___handle
          products___variants___id
        ]
      }
    ) {
      edges {
        node {
          id
          shopifyId
          title
          handle
          metafields {
            type
            key
            value
            description
            namespace
          }
          products {
            id
            shopifyId
            title
            productType
            variants {
              id
              shopifyId
              price
              title
              availableForSale
              inventoryPolicy
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default CollectionTemplate;
