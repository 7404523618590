import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, isEqual, tail } from '../../lib/nodash';
import { Box } from 'grommet';

import ProductCollectionGrid from '../Product/ProductCollectionGrid';
import CollectionHeader from '../Collection/CollectionHeader';

const PageCollection = ({ collection, content, paintCollections, title }) => {
  const { products } = collection;

  const isSupplies = isEqual('supplies', get('handle', collection));
  const filteredProducts = isSupplies ? tail(products) : products;
  const isDesignerCollection = collection.template?.value === 'Designer Items';

  return (
    <Box>
      {content && content.length ? (
        content
      ) : (
        <CollectionHeader
          title={title || collection.title}
          image={isDesignerCollection ? undefined : collection.image}
          description={collection.description}
          isDesigner={isDesignerCollection}
          border={0}
          columns={
            isDesignerCollection
              ? { count: 1, size: 'auto' }
              : { count: 2, size: 'auto' }
          }
        />
      )}

      {products.length > 0 && (
        <ProductCollectionGrid
          products={filteredProducts}
          collection={collection}
          margin={{ vertical: 'medium' }}
          paintCollections={paintCollections}
          maxCols={isDesignerCollection ? 3 : 4}
          pad={isDesignerCollection ? { horizontal: 'large' } : undefined}
        />
      )}
    </Box>
  );
};

PageCollection.propTypes = {
  collection: PropTypes.object.isRequired,
  page: PropTypes.object,
  content: PropTypes.node,
  paintCollections: PropTypes.array,
  finish: PropTypes.string,
  paintArea: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default memo(PageCollection);
